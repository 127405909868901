@import '../../styles/helpers/index.scss';

.get-in-touch-form-wrapper {
  form {
    @include grid(0, 40px, 1fr, repeat(2, 1fr));

    input,
    textarea,
    select {
      outline: none;
      border: none;
      &:not([type='submit']) {
        width: 100%;
        margin: 0 0 20px;
        padding: 10px 25px;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid #fff;
        color: #fff;
        font-size: 16px;
        appearance: none;
        -webkit-appearance: none;
        &::placeholder {
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 16px;
          line-height: 155%;
          color: #fff;
        }
      }
    }

    input[type='number'] {
      appearance: none;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    input,
    select {
      height: 50px;
      &[type='submit'] {
        cursor: pointer;
        margin: 20px 0 0;
      }
    }

    textarea {
      height: 190px;
    }
  }
}
