@import '../../styles/helpers/index.scss';

.page-footer {
  color: #fff;
  background-color: map-get($colors, 'blue');

  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  .container {
    @include align-center;
    flex-direction: column;
  }

  &__logo {
    padding: 8px 0;

    height: 140px;
    width: 140px;

    background-color: #fff;
    @include align-center;
    justify-content: center;

    margin: 0 0 45px;

    @media screen and (max-width: $tablet-sm) {
      height: 100px;
      width: 100px;
    }
  }

  a {
    color: #fff;
    &[href^='tel:'] {
      font-size: 40px;
      line-height: 60px;
      margin: 0 0 35px;
    }

    &[href^='mailto:'] {
      font-size: 24px;
      line-height: 36px;
      margin: 0 0 40px;
    }
  }

  &__address {
    max-width: 300px;
    text-align: center;
  }

  &__underline {
    margin: 50px 0 0;

    @media screen and (max-width: $tablet-sm) {
      padding: 0 0 60px;
    }
    .container {
      border-top: 1px solid #fff;
      padding-top: 20px;
      padding-bottom: 20px;

      font-size: 12px;
      line-height: 180%;

      user-select: none;

      & > div {
        width: 100%;
        @include space-between;
        margin: 0 0 30px;

        @media screen and (max-width: $tablet-sm) {
          flex-direction: column;
          text-align: center;
        }
      }
    }

    a {
      margin-top: 10px;
      strong {
        font-weight: bold;
      }
    }

    &--links {
      @media screen and (max-width: $tablet-sm) {
        margin: 15px 0 0;
      }
      a {
        color: #fff;
        &:not(:last-of-type) {
          margin: 0 10px 0 0;
        }

        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
}
