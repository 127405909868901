@import '../../styles/helpers/index.scss';

.reviews {
  margin: 140px 0 0;
  padding: 0 0 140px;

  background: linear-gradient(to bottom, #fff 60%, #f0f0f0 40%);

  @media screen and (max-width: $tablet-sm) {
    margin: 50px 0 0;
  }

  &__list {
    margin: 65px 0 0;

    @media screen and (max-width: $tablet-sm) {
      margin: 90px 0 0;
    }

    &--card {
      background: #ffffff;
      border: 1px solid map-get($colors, 'border');
      margin: 0 20px;

      @media screen and (max-width: $tablet-sm) {
        margin: 0 auto;
      }

      &__header {
        padding: 0 30px;
        background-color: #f0f0f0;
        height: 80px;
        @include align-center;
        justify-content: space-between;

        &--resource {
          font-size: 46px;
          color: map-get($colors, 'blue');
        }

        &--stars {
          @include align-center;
          color: map-get($colors, 'red');
          font-size: 20px;
          i:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }

      &__body {
        padding: 0 30px 60px;
        color: map-get($colors, 'text');
      }

      &__date {
        display: block;
        margin: 30px 0 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
      }

      &__name {
        display: block;
        margin: 20px 0 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
      }

      p {
        margin: 30px 0 0;
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        height: 270px;
        overflow: scroll;

        @media screen and (max-width: $tablet-sm) {
          height: auto;
          overflow: unset;
        }
      }
    }
  }

  .slick-slider {
    .slick-next,
    .slick-prev {
      width: 60px;
      height: 60px;
      background: #fff;
      border: 1px solid map-get($colors, 'blue');
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: -100px;
      cursor: pointer;
      &::before {
        font-family: 'fontello';
        color: map-get($colors, 'blue');
      }
      @media screen and (max-width: $tablet-sm) {
        top: -30px;
        width: 45px;
        height: 45px;
      }
    }
    .slick-next {
      right: 20px;
      &::before {
        content: '\f105';
      }
      @media screen and (max-width: $tablet-sm) {
        right: 0;
      }
    }
    .slick-prev {
      right: 150px;
      left: unset;
      &::before {
        content: '\f104';
      }
      @media screen and (max-width: $tablet-sm) {
        right: auto;
        left: 0;
      }
    }
  }
}
