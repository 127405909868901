@import '../../styles/helpers/index.scss';

.call-us {
  margin: 240px 0 0;
  padding: 90px 0 40px;

  background-image: url('../../static/images/call-us-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  color: #fff;

  @media screen and (max-width: $tablet-sm) {
    margin: 200px 0 0;
  }

  .section-title {
    margin: 0 0 30px;
    max-width: 670px;
    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
    }
  }

  p {
    margin: 0 0 50px;
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    max-width: 920px;
    @media screen and (max-width: $tablet-sm) {
      font-size: 18px;
      margin: 0 0 35px;
    }
  }

  &__link {
    @include align-center;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    span {
      margin: 0 0 0 55px;
      font-weight: 600;
      font-size: 30px;
      line-height: 150%;
      @media screen and (max-width: $tablet-sm) {
        font-size: 18px;
        margin: 25px 0 0;
      }
    }
  }

  &__payments {
    margin: 10px 0 0;
    @include align-center;
    justify-content: flex-end;
    @media screen and (max-width: $tablet-sm) {
      margin: 45px 0 0;
      justify-content: space-between;
    }
    img {
      height: 50px;
      object-fit: cover;
      &:not(:last-of-type) {
        margin: 0 20px 0 0;
        @media screen and (max-width: $tablet-sm) {
          margin: 0;
        }
      }
    }
  }
}
