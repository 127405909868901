@import '../../styles/helpers/index.scss';

.page-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  text-align: right;
  z-index: 100;
  transition: box-shadow 0.3s ease;

  &.shadow {
    box-shadow: 0px 5px 15px 0px rgba(#000, 0.5);
  }

  &__headline {
    position: relative;
    background-color: map-get($colors, 'blue');
    color: #fff;
    height: 40px;
    span {
      font-size: 18px;
      line-height: 40px;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    &--phone {
      display: none;

      color: #fff;
      font-size: 18px;
      font-weight: bold;
      line-height: 40px;

      &::before {
        content: '\e806';
        font-family: 'fontello';
        margin-right: 10px;
      }

      @media screen and (max-width: $tablet-sm) {
        display: block;
      }
    }
  }

  &__logo {
    padding: 10px 7px;

    position: absolute;
    left: 0;
    top: 0;
    width: 140px;
    height: 140px;

    z-index: 3;

    background-color: #fff;

    @include align-center;
    justify-content: center;

    filter: drop-shadow(0px 4px 4px rgba(#000, 0.25));

    @media screen and (max-width: $desktop-sm) {
      left: 15px;
    }

    @media screen and (max-width: $tablet-sm) {
      left: 10px;
      width: 110px;
      height: 110px;
      padding: 7px 5px;
    }
  }

  &__links {
    background-color: #fff;
    height: 80px;

    @media screen and (max-width: $tablet-sm) {
      height: 60px;
    }

    .container {
      @include align-center;
      justify-content: flex-end;
    }

    &--phone {
      margin-right: 40px;
      font-size: 26px;
      line-height: 39px;
      color: map-get($colors, 'text');

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }

      &::before {
        content: '\e806';
        font-family: 'fontello';
        font-size: 30px;
        margin-right: 15px;
        color: map-get($colors, 'blue');
      }
    }
  }
}
