@import '../../styles/helpers/index.scss';

.satisfaction {
  margin: 70px 0 0;

  &__links {
    @include grid(20px, 30px, 1fr, repeat(3, 1fr));
    margin: 45px 0 0;
    & > div {
      @include align-center;
      justify-content: space-around;
      background: #ffffff;
      border: 1px solid #115a85;
      padding: 45px 0;
      @include scale;
    }

    &--logo {
      height: 100px;
      width: 100px;
      display: flex;
      justify-content: center;

      @media screen and (max-width: $tablet-sm) {
        height: 80px;
        width: 80px;
      }

      @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
        height: 60px;
        width: 60px;
      }
    }

    &--reviews {
      text-align: center;
      div {
        white-space: nowrap;
      }
      i {
        color: map-get($colors, 'red');
        font-size: 25px;
        @media screen and (max-width: $tablet-sm) {
          font-size: 18px;
        }
        @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
          font-size: 16px;
        }
        &:not(:last-of-type) {
          margin-right: 12px;
          @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
            margin-right: 6px;
          }
          @media screen and (max-width: $tablet-sm) {
            margin-right: 8px;
          }
        }
      }
      span {
        display: block;
        margin: 30px 0 0;
        font-size: 24px;
        line-height: 110%;
        color: map-get($colors, 'text');
        @media screen and (max-width: $tablet-sm) {
          margin: 20px 0 0;
          font-size: 18px;
        }
        @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
          margin: 10px 0 0;
          font-size: 16px;
        }
      }
    }
  }
}
