@import '../../styles/helpers/index.scss';

.areas {
  position: relative;
  padding: 100px 0;
  // background-image: url('../../static/images/areas-bg.png');
  background-image: url('../../static/images/areas-bg2.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  color: #fff;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(#000, 0.5);
  }

  @media screen and (max-width: $tablet-sm) {
    padding: 85px 0 75px;
  }

  ul {
    margin: 25px 0 0;
    columns: 3;

    @media screen and (max-width: $tablet-sm) {
      columns: 1;
    }

    li {
      display: flex;
      align-items: center;
      font-weight: 300;
      font-size: 18px;
      line-height: 200%;
      &::before {
        content: '\f105';
        font-family: 'fontello';
        color: map-get($colors, 'red');
        margin-right: 15px;
        font-weight: bold;
      }
    }
  }
}
