@import '../../styles/helpers/index.scss';

.services {
  margin: 140px 0 0;
  padding: 115px 0 140px;
  background-color: map-get($colors, 'blue');
  color: #fff;

  @media screen and (max-width: $tablet-sm) {
    margin: 120px 0 0;
    padding: 90px 0 100px;
  }

  @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
    margin: 90px 0 0;
    padding: 90px 0;
  }

  .section-title {
    margin: 0 0 60px;

    @media screen and (max-width: $tablet-sm) {
      margin: 0 0 50px;
    }
  }

  &__grid {
    @include grid(40px, 40px, repeat(2, 1fr), repeat(4, 1fr));

    @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
      @include grid(40px, 40px, repeat(2, 1fr), repeat(2, 1fr));
    }

    &--item {
      position: relative;
      padding: 60px 0;
      background-color: #fff;
      color: map-get($colors, 'text');
      text-align: center;
      width: 100%;
      height: 280px;
      @include scale;

      @media screen and (max-width: $tablet-sm) {
        width: 280px;
        margin: 0 auto;
      }

      &::before {
        content: '';
        background-image: url('../../static/images/link-arrow.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 30px;
        height: 26px;

        position: absolute;
        right: 10px;
        top: 10px;
      }

      &__icon {
        img {
          margin: 0 auto;
        }
      }

      h5 {
        margin: 50px 0 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
      }
    }
  }
}
