@import '../../styles/helpers/index.scss';

.get-in-touch {
  position: relative;
  margin: 70px 0 0;
  background-color: #1e96e1;
  padding: 100px 0 120px;
  color: #fff;
  overflow: hidden;

  &::before {
    content: '';
    background-image: url('../../static/images/get-in-touch-pseudo.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    width: 609px;
    height: 288px;

    position: absolute;
    top: -30px;
    right: 5%;
  }

  .section-title {
    margin: 0 0 30px;
  }

  .subtitle {
    margin: 20px 0 50px;
    font-weight: 300;
    font-size: 24px;
    line-height: 120%;
  }

  .flex {
    margin: 15px 0 0;
    display: flex;
    align-items: center;
    line-height: 100%;

    @media screen and (max-width: $tablet-sm) {
      // margin: 0 0 30px;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      fill: #fff;
    }
  }
}
