@import '../../styles/helpers/index.scss';

.page-content {
  margin: 155px 0 0;

  display: flex;

  @media screen and (max-width: $tablet-sm) {
    margin: 90px 0 0;

    flex-direction: column;
  }

  @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
    margin: 90px 0 0;
    flex-direction: column;
  }

  &__text {
    padding-left: 100px;
    margin-right: 75px;

    @media screen and (max-width: $tablet-sm) {
      padding: 0 15px;
      margin: 0 0 20px;
    }

    @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
      padding: 0 15px;
      margin: 0 0 20px;
    }

    .section-title {
      margin: 0 0 35px;
      @media screen and (max-width: $tablet-sm) {
        margin: 0 0 40px;
      }
    }

    p {
      margin: 0 0 35px;
      font-weight: 300;
      font-size: 18px;
      line-height: 155%;

      @media screen and (max-width: $tablet-sm) {
        margin: 0 0 20px;
      }

      strong {
        font-weight: bold;
      }
    }

    ul {
      font-weight: 300;
      font-size: 18px;
      line-height: 200%;

      li {
        @include align-center;
        position: relative;
        &::before {
          content: '\e801';
          font-family: 'fontello';
          color: map-get($colors, 'red');
          font-size: 12px;
          margin-right: 10px;
        }
      }
    }
  }

  &__image {
    min-height: 550px;
    min-width: 710px;
    background-color: map-get($colors, 'border');
    padding: 10px 0 10px 10px;

    @media screen and (max-width: $tablet-sm) {
      min-height: unset;
      min-width: unset;
      width: 100%;
      height: auto;
      padding: 10px;
      margin: 0 auto;
    }

    @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media screen and (max-width: $tablet-sm) {
        max-height: 350px;
      }
    }
  }
}
