@import '../../styles/helpers/index.scss';

.why-we {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .container {
    display: flex;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      &:first-of-type {
        margin-right: 60px;
      }
    }
  }

  .section-title {
    max-width: 600px;
    margin: 0 0 65px;

    @media screen and (max-width: $tablet-sm) {
      margin: 0 0 50px;
    }
  }

  &__content {
    &--grid {
      @include grid(40px, 40px, repeat(2, 1fr));

      &__item {
        color: map-get($colors, 'text');

        &--header {
          display: flex;
          align-items: center;
          margin: 0 0 30px;

          &__icon {
            @include align-center;
            justify-content: center;
            background-color: map-get($colors, 'blue');
            width: 80px;
            height: 80px;
            margin-right: 30px;

            @media screen and (max-width: $tablet-sm) {
              width: 60px;
              height: 60px;
              padding: 8px 0;
              margin-right: 20px;
            }

            img {
              @media screen and (max-width: $tablet-sm) {
                max-height: 45px;
                height: 100%;
              }
            }
          }

          h4 {
            max-width: 215px;
            font-size: 24px;
            line-height: 120%;
            @media screen and (max-width: $tablet-sm) {
              font-size: 18px;
              line-height: 120%;
            }
          }
        }
        p {
          font-weight: 300;
          font-size: 18px;
          line-height: 155%;
        }
      }
    }
  }

  &__coupon {
    max-width: 400px;
    width: 100%;
    border: 1px dashed map-get($colors, 'border');
    position: relative;
    padding: 20px;

    @media screen and (max-width: $tablet-sm) {
      padding: 10px;
      margin: 60px 0 0;
      max-width: 100%;
    }

    @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
      display: none;
    }

    &::before {
      content: '';
      background-image: url('../../static/images/coupon-pseudo.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 26px;
      height: 40px;

      position: absolute;
      right: -13px;
      bottom: 5%;

      @media screen and (max-width: $tablet-sm) {
        width: 20px;
        height: 30px;
        right: -10px;
      }
    }

    &--inner {
      background-color: #f0f0f0;
      height: 100%;

      @include align-center;
      flex-direction: column;

      padding: 35px 0 55px;

      h2 {
        color: map-get($colors, 'red');
        font-weight: 600;
        font-size: 64px;
        line-height: 120%;
        text-align: center;
        margin: 0 0 15px;
        span {
          font-size: 80px;
          display: block;
        }
      }

      & > span {
        font-weight: 600;
        font-size: 46px;
        line-height: 150%;
        color: map-get($colors, 'blue');
        margin: 0 0 25px;
      }

      p {
        font-weight: 300;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        margin: 0 0 45px;
      }

      a {
        &[href^='tel'] {
          margin: 50px 0 0;
          font-weight: 600;
          font-size: 26px;
          line-height: 39px;
          color: map-get($colors, 'text');
          &::before {
            content: '\e806';
            font-family: 'fontello';
            margin-right: 10px;
            color: map-get($colors, 'blue');
          }
        }
      }
    }
  }
}
