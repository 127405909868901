@import '../../styles/helpers/index.scss';

.cta {
  position: relative;
  margin: 140px 0 0;
  padding: 80px 0 90px;

  background-image: url('../../static/images/cta-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  color: #fff;

  @media screen and (max-width: $tablet-sm) {
    margin: 100px 0 0;
  }

  &::before {
    content: '';
    background-image: url('../../static/images/ticket.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    width: 260px;
    height: 260px;

    position: absolute;
    top: -30%;
    right: 15%;
    z-index: 2;

    @media screen and (max-width: $tablet-sm) {
      padding: 65px 0 45px;
      height: 120px;
      width: 120px;
      top: -15%;
      right: 5%;
    }

    @media screen and (max-width: $tablet-lg) and (orientation: landscape) {
      right: 5%;
      top: -25%;
      width: 200px;
      height: 200px;
    }
  }

  .section-title {
    margin: 0 0 35px;
    @media screen and (max-width: $tablet-sm) {
      margin: 0 0 25px;
      max-width: 300px;
    }
  }

  &__links {
    @include align-center;
    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    a {
      color: #fff;
    }
    span {
      font-weight: 600;
      font-size: 24px;
      line-height: 200%;
      margin-left: 40px;
      @media screen and (max-width: $tablet-sm) {
        margin: 20px 0 0 0;
        ont-size: 18px;
      }
      a {
        text-decoration: underline !important;
      }
    }
  }
}
